.Container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  position: relative;
  .Cards {
    flex: 1;
  }
}

@media (max-width: 1160px) {
  .Container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .Cards {
      width: 100%;
      order: 2;
    }
    .Filters {
      width: 100%;
      order: 1;
    }
  }
}
