.center {
  margin: auto;
}

.form-login {
  padding: 20px;
  border-radius: 15px;
  margin: 100px auto;
  width: 100%;
  max-width: 500px;
  background-color: #f5f5f5;
}
