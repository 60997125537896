.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}
.ant-layout-sider-children {
  width: inherit !important;
}
/* .ant-drawer-body {
  width: inherit !important;
}
.ant-drawer-content {
  width: inherit !important;
} */
.ant-menu-root {
  flex: 1 0 auto;
}
