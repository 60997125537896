.Sider {
}
.Layout {
  margin-inline-start: 200px;
}
.Header {
  display: none;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid #f0f0f0;
  padding: 0 20px;
}
.Menu {
  margin: 55px 0;
  border-inline-end: none !important;
}

.MenuFioStyles {
  min-height: 50px;
  height: 50px;
  position: fixed;
  display: flex;
  /* justify-content: center; */
  padding-left: 24px;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 200px;
}

.DrawerFioStyles {
  min-height: 50px;
  height: 50px;
  position: fixed;
  display: flex;
  /* justify-content: center; */
  margin-right: -48px;
  align-items: center;
  bottom: 0;
  right: 0;
  width: 500px;
}

@media (max-width: 900px) {
  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .Sider {
    display: none;
  }
  .Layout {
    margin-inline-start: 0;
  }
}

@media (max-width: 500px) {
  .DrawerFioStyles {
    width: 100%;
  }
}
