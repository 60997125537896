.item-enter {
  opacity: 0;
  max-height: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.item-enter-active {
  max-height: 2000px;
  opacity: 1;
  will-change: auto;
  transition: opacity 200ms 300ms, max-height 300ms ease-in-out, padding 300ms, margin 300ms ease-in-out;
}

.item-exit {
  max-height: 2000px;
  opacity: 1;
}

.item-exit-active {
  max-height: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
  opacity: 0;
  will-change: auto;
  transition: opacity 200ms, max-height 300ms 200ms ease-in-out, padding 300ms 200ms ease-in-out, margin 300ms 200ms ease-in-out;
}

.fade-enter {
  opacity: 0;
  max-height: 0px !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
}

.fade-enter-active {
  max-height: 500px !important;
  opacity: 1;
  will-change: contents;
  transition: opacity 200ms 300ms, max-height 300ms ease-in-out, padding 300ms, margin 300ms ease-in-out;
}

.fade-exit {
  max-height: 500px !important;
  opacity: 1;
}

.fade-exit-active {
  max-height: 0px !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  opacity: 0;
  will-change: contents;
  transition: opacity 200ms, max-height 300ms 200ms ease-in-out, padding 300ms 200ms ease-in-out, margin 300ms 200ms ease-in-out;
}
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.no-bills {
  opacity: 0;
  animation-name: appear;
  animation-duration: 0.3s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}
