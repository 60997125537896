.CardContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.CardTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  font-weight: 600;
  font-size: 22px;
}

.Steps {
  width: 180px;
}

.StepTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 16.34px;
  margin-bottom: 8px !important;
  .StepDate {
    font-size: 15px;
    margin-top: 3px;
  }
}
.StepDescription {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.34px;
  color: rgba(0, 0, 0, 0.5);
}
.CardRows {
  display: flex;
  justify-content: flex-start;
  gap: 70px;
  margin: 30px 0 10px 0;
}
.CardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.List {
  display: grid;
  gap: 12px 35px;
  grid-template-columns: 170px 1fr;
  .ListTitle {
    margin: 0;
  }
}

.BillCard {
  :global {
    .ant-card-body {
      padding: 20px;
    }
  }
}

.Tag {
  font-size: 14;
  padding: 3px 7px;
}
.DetailsButton {
  :global {
    .ant-btn {
      padding: 2px 2px !important;
    }
  }
}

.Details {
  display: none;
}

.LabelRequired {
  display: flex;
  margin-bottom: 5px;
  &::before {
    content: "*";
    color: red;
    margin-right: 2px;
  }
}

@media (max-width: 1260px) {
  .CardTitle {
    display: block;
  }
  .CardHeader {
    align-items: start;
  }
  .BillCard {
    :global {
      .ant-card-body {
        padding: 16px;
      }
    }
  }
  .CardRows {
    gap: 20px;
  }
  .List {
    grid-template-columns: 115px 1fr;
    gap: 8px 12px;
    .ListTitle {
      font-size: 14px;
    }
  }
  .StepTitle {
    font-size: 14px;
    .StepDate {
      font-size: 14px;
    }
  }
  .StepDescription {
    font-size: 13px;
  }
}
@media (max-width: 1160px) {
  .Steps {
    width: auto;
  }
}

@media (max-width: 900px) {
  .CardTitle {
    font-size: 16px;
    .CardTitleFrom {
      font-size: 12px;
    }
  }
  .CardRows {
    display: flex;
    flex-direction: column;
    gap: 17px;
    margin: 16px 0;
  }
  .Tag {
    font-size: 10;
    padding: 1px 2px;
    margin: 0;
  }
  .StepTitle {
    font-size: 13px;
    margin-bottom: 2px !important;
    display: flex;
    align-items: center;
    .StepDate {
      font-size: 13px;
      margin: 0 0 0 3px;
    }
  }
  .StepDescription {
    font-size: 13px;
  }
}

@media (max-width: 650px) {
  .CardContent {
    display: block;
  }
  .List {
    font-size: 12px;
    .ListTitle {
      font-size: 13px;
    }
  }
}
