.Title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}

@media (max-width: 1260px) {
  .Title {
    font-size: 18px;
  }
}

@media (max-width: 900px) {
  .Datepicker {
    :global {
      .ant-picker-panels {
        flex-direction: column !important;
      }
    }
  }
}
