@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.no-bills {
  opacity: 0;
  animation-name: appear;
  animation-duration: 0.3s;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;
}
