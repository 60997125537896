.Container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  position: relative;
  .Cards {
    flex: 1;
  }
}

.ModalTitle {
  font-size: 24px;
  margin-bottom: 20px;
}

.ModalItem {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  .ModalLabel {
    width: 125px;
  }
  .ModalLabelRequired {
    width: 125px;
    display: flex;
    &::before {
      content: "*";
      color: red;
      margin-right: 2px;
    }
  }
  .ModalObject {
    max-width: 336px;
    width: 100%;
  }
}

@media (max-width: 550px) {
  .ModalItem {
    flex-direction: column;
    justify-content: start;
    .ModalLabel {
      width: 100%;
    }
    .ModalLabelRequired {
      width: 100%;
      display: flex;
      &::before {
        content: "*";
        color: red;
        margin-right: 2px;
      }
    }
    .ModalObject {
      max-width: none;
      width: 100%;
    }
  }
}

@media (max-width: 1160px) {
  .Container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .Cards {
      width: 100%;
      order: 2;
    }
    .Filters {
      width: 100%;
      order: 1;
    }
  }
}
